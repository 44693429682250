ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}
p {
    text-align: justify;
}
ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
    font-weight: bold;
}

ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}
span {
    font-weight: normal;

}
li ol > li {
    margin: 0;
    text-align: justify;
}

li ol > li:before {
    content: counters(item, ".") " ";
}