/* Form */
.Form {
  text-align: center;
  max-width: 860px;
  margin: 0 auto;
}
.form-group .file {
  margin-bottom: 5px !important;
}
.helpTooltip {
  max-width: 300px;
  margin-left: 35px;
}
.year{
  margin-left: 50px;
}
.month{
  margin-left: 562px;
}
.helpTooltip .tooltip-inner{
  max-width: 300px;
  padding: .25rem .5rem;
  color: #000;
  text-align: left;
  background-color: #f6f8fb;
  border-radius: .25rem;
  border: solid 1px #9cd5f3;
}
.helpTooltip .arrow {
  display: none;
}

.hintTooltip {
  max-width: 300px;
  margin-left: 6px;
}

.hintTooltip .tooltip-inner {
  max-width: 300px;
  padding: .25rem .5rem;
  color: #000;
  text-align: left;
  background-color: #f6f8fb;
  border-radius: .25rem;
  border: solid 1px #9cd5f3;
}

.hintTooltip .arrow {
  display: none;
}

.subhintTooltipFederal .tooltip-inner {
  font-family: 'RobotoRegular';
  background-color: #0071bc;
  color: #ffffff;
}

.processing-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #c7c8ca;
}

.Form #numberOfAgricultureEmployees.form-control.is-invalid,
.Form #numberOfHouseholdeEmployees.form-control.is-invalid,
.Form #numberOfOtherEmployees.form-control.is-invalid,
.Form #firstDateWagesMonth.form-control.is-invalid,
.Form #firstDateWagesDay.form-control.is-invalid,
.Form #firstDateWagesYear.form-control.is-invalid,
.Form #startDateYear.form-control.is-invalid,
.Form #tradeName.form-control.is-invalid,
.Form #altName.form-control.is-invalid,
.Form input[type="text"].is-invalid,
.Form input[type="email"].is-invalid{
  border-color: #dc3545;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
}

.Form input[type="email"].is-valid{
  border-color: #28a745;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}

.Form h1 {
  text-transform: uppercase;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 1.2vw;
  color: #000000;
  margin-top: 0px;
}

.Form h2 {
  font-size: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: #555;
}

.Form h3 {
  font-size: 25px;
  margin-top: 0px;
  margin-bottom: 25px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: #555;
}

.Form h5 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #434343;
  margin-top: 0px;
  text-align: left;
  padding: 20px;
}

.Form label {
  color: #434343;
  font-size: 16px;
  position: relative;
  top: 16px;
}

.Form input[type="text"],
.Form input[type="phone"],
.Form input[type="password"],
.Form input[type="email"],
.Form input[type="tel"],
.Form textarea,
.Form select {
  color: #434343;
  font-size: 16px;
  height: 56px;
  padding: 15px;
  width: auto;
  min-width: 515px;
  max-width: 515px;
  border: solid 2px #c7c8ca;
  border-radius: 2px;
  float: right;
}
.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

i {
  position: relative;
  top: 16px;
  right: -560px;
}
i:hover {
  color: #00fcb6;
  cursor: pointer;
}
.Form button[type="submit"] {
  padding: 15px 20px;
  background: #101661;
  border: 0 none;
  cursor: pointer;
  -webkit-border-radius: 5px;
  border-radius: 2px;
  color: #ffffff;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.Form hr {
  border-top: 1px dashed #9a9a9a;
  margin: 30px 0 30px 0;
}

.form-row,
.form-group {
  text-align: left;
  padding: 0 20px 0 20px;
  display: flow-root;
  position: relative;
}

.form-control-loader {
  position: absolute;
  top: calc((56px - 2rem) / 2);
  right: 55px;
}

.Form .radio label {
  width: 40%;
  display: inline-block;
  top: 0px;
}

.Form .radio .col {
  width: 50%;
  display: inline;
  vertical-align: top;
}

.Form .message {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
  margin-top: 0px;
  text-align: left;
  background-color: #f2f2f2;
  padding: 25px 15px 20px 15px;
}

.Form .border-check .form-check {
  position: relative;
  display: block;
  padding-left: 2.25rem !important;
  padding: 15px 10px 10px 20px;
  border: 1px #c7c8ca solid;
  margin-bottom: 5px;
}

.Form .border-check .form-check-label {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #434343;
  top: 0px;
  width: 100%;
}

.Form.payment .form-group {
  padding-left: 0px;
  padding-right: 0px;
}

.inline-check-wrapper .col {
  display: inline;
}

.inline-check-wrapper .form-label,
.form-check.form-check-inline label {
  top: 0px;
}

.Form .left-check label {
  top: 0px;
}

.Form .dates select {
  color: #434343;
  font-size: 18px;
  height: 60px;
  padding: 15px;
  width: auto;
  min-width: 245px;
  border: solid 2px #c7c8ca;
  border-radius: 2px;
}

.Form #closingMonth {
  min-width: 52%;
}

.Form .dates .form-row {
  display: flex;
}

label[for="legalName"] {
  width: 28%;
  top: 5px;
}
label[for="ClientAgreement"] {
  position: initial;
  font-family: Source Sans Pro;
  font-weight: 400;
}
label[for="productDescription"],
label[for="productExtraQuestion1"],
label[for="productExtraQuestion2"],
label[for="productExtraQuestion3"],
label[for="specificProductsLabel"],
label[for="activitySpecificProducts"] {
  top: 0px;
}

label[for="organizedState"],
label[for="dbaname"],
label[for="previousLast"],
label[for="numberOfAgricultureEmployees"],
label[for="numberOfHouseholdeEmployees"],
label[for="previousEINNumberFirst2"],
label[for="previousEINNumberLast7"],
label[for="corpAddressState"]{
  width: 30%;
  top: 3px;
}

label[for="numberOfOtherEmployees"] {
  margin-bottom: 35px;
}

.Form.non-profit label[for="dbaname"] {
  top: 16px;
}

.Form.payment .custom-label p:nth-child(2) {
  font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
}

.Form.payment .border-check input[type="radio"]:not(:checked) ~ label {
  color: #cccccc;
}

.Form.payment .custom-label p {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0px;
}

.Form.payment .custom-label .price {
  position: absolute;
  top: 10px;
  right: 2%;
}

.Form.payment hr {
  margin: 10px 0 10px 0;
}

.Form.payment h5 {
  padding-left: 0px;
  padding-bottom: 0px;
}

.Form.products {
  max-width: 100%;
}

.Form.products h1 {
  text-align: center;
}

.Form.contact .radio label {
  width: 33%;
}

.Form textarea.form-control {
  height: 175px;
}

.Form.payment #cardNumber {
  min-width: 245px;
  margin-right: 0%;
  float: right;
}

.Form.payment #cardsImg {
  float: right;
  margin-left: 16px;
  margin-top: 5px;
}

.question {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAYFBMVEVHcEwBdtwARMwAZswAXNYAbNUAeuAAM5kAjfYSpv8Aie9Otv8AhOp+xv83qPtauv8AYsgXl/YDlvwprv8cqP8AgOYAUMqTzP8RiOgYceH///+s1/8GqP8PgeIcpv83sP8++YT4AAAAAXRSTlMAQObYZgAAAIdJREFUeF5ti0cOAzEMxCzJtWyv6f//ZeRZ5BYCcyCBMUCAAdAH+CXpSokx5uk45PL358aKPc9OEOYb3xW2rxZkmGZ1biMa5F/I0THckmuh1Bzj5eSKht7Xmlndkxt7BL9tlttBRIMJC5FvF/UlGA2rflsQkVWDlpTGnXkfU1JHCU8Q4CAAyBfEVgiy+BC8BAAAAABJRU5ErkJggg==)
    50% no-repeat;
  content: "";
}

label[for="cardNumber"] {
  float: left;
}

.inline-check-wrapper.billing-address .form-label {
  margin-right: 11%;
}

.Form.payment .expiry-date-wrapper {
  width: auto;
  float: right;
  min-width: 515px;
  max-width: 515px;
}
.Form.payment .expiry-date-wrapper .form-group {
  display: inline-block;
  margin-bottom: 0px;
}
.Form.payment .expiry-date-wrapper select {
  min-width: 200px;
  margin-right: 20px;
}

/* Mobile view */
@media (max-width: 1024px) {
  .Form input[type="text"],
  .Form input[type="phone"],
  .Form input[type="password"],
  .Form input[type="email"],
  .Form textarea,
  .Form select {
    min-width: 200px;
    width: 100%;
  }

  .Form label {
    top: 0px;
    width: auto;
  }

  .Form .radio label {
    width: 60%;
  }

  .Form h1 {
    font-size: 22px;
    margin-right: 0px;
  }

  .Form.contact .radio label {
    width: 50%;
  }

  label[for="legalName"],
  label[for="organizedState"],
  label[for="dbaname"] {
    width: auto;
  }
}

/*iPad Pro*/
@media (max-width: 1024px) and (min-width: 769px) {
  .Form .dates select {
    min-width: 225px;
  }

  .products a span {
    font-size: 20px;
  }

  label[for="cardNumber"] {
    margin-right: 21%;
  }
  i {
    position: relative;
    top: 18px;
    right: -70%;
  }
  .Form.payment #lastName,
  .Form.payment #firstName {
    width: auto;
    min-width: 515px;
  }

  .inline-check-wrapper.billing-address .form-label {
    margin-right: 5%;
  }
  .Form.payment .expiry-date-wrapper .form-group {
    width: 201px;
    margin-right: 20px;
  }
  .Form.payment .expiry-date-wrapper select {
    margin-right: 0px;
  }
}

/*iPad*/
@media (max-width: 768px) and (min-width: 442px) {
  label[for="numberOfAgricultureEmployees"],
  label[for="numberOfHouseholdeEmployees"],
  label[for="numberOfOtherEmployees"] {
    width: 100%;
    top: 0px;
    margin-bottom: 0px;
  }
  .Form h2 {
    font-size: 30px;
  }

  .Form h3 {
    font-size: 18px;
  }

  .Form .dates select {
    min-width: 187px;
  }

  .products a span {
    font-size: 16px;
  }
  i {
    position: relative;
    top: 46px;
    right: -64%;
  }
  label[for="cardNumber"] {
    margin-right: 7%;
  }

  .Form.payment #lastName,
  .Form.payment #firstName {
    width: auto;
    min-width: 515px;
  }

  .inline-check-wrapper.billing-address .form-label {
    margin-right: 5%;
  }
  .Form.payment .expiry-date-wrapper .form-group {
    margin-right: 20px;
  }
  .Form.payment .expiry-date-wrapper select {
    margin-right: 0px;
  }
  .form-control-loader {
    top: calc((56px - 2rem) / 2 + 32px);
    right: 55px;
  }
}

@media (max-width: 441px) {
  .Form h2 {
    font-size: 20px;
  }

  .Form h3 {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .Form label, .Form.non-profit label[for="dbaname"] {
    top: 0px;
    width: auto;
  }

  .Form input[type="text"],
  .Form input[type="phone"],
  .Form input[type="password"],
  .Form input[type="email"],
  .Form select {
    float: none;
  }
  i {
    position: relative;
    top: -39px;
    right: -85%;
  }
  .form-row,
  .form-group {
    padding: 0 0px 0 0px;
  }

  .Form #closingMonth,
  .Form #organizedState {
    min-width: 100%;
  }

  .Form .dates select {
    min-width: 100%;
  }

  .Form hr {
    margin: 10px 0 10px 0;
  }

  .Form .radio label {
    width: 100%;
  }

  .Form {
    padding-right: 5px;
    padding-left: 5px;
  }

  .Form.payment #cardNumber {
    margin-right: 0%;
    float: none;
    min-width: 200px;
    width: 100%;
  }

  .Form.payment input[type="tel"] {
    min-width: 200px;
    width: 100%;
  }

  .inline-check-wrapper.billing-address .form-label {
    margin-right: 0%;
    width: 54%;
    vertical-align: top;
  }

  label[for="numberOfAgricultureEmployees"],
  label[for="numberOfHouseholdeEmployees"],
  label[for="numberOfOtherEmployees"] {
    width: 100%;
    top: 0px;
    margin-bottom: 0px;
  }

  .form-control-loader {
    top: calc((56px - 2rem) / 2 + 32px);
    right: 35px;
  }
}

@media (max-width: 640px) and (min-width: 321px) {
  .Form.payment #cardsImg {
    margin-left: 0px;
    max-width: 190px;
    margin-top: -2px;
    padding-bottom: 5px;
  }

  .Form.payment .custom-label p {
    width: 80%;
  }

  .Form.payment .custom-label .price {
    top: 0px;
  }
  .Form.payment .expiry-date-wrapper {
    width: auto;
    min-width: auto;
  }
  .Form.payment .expiry-date-wrapper .form-group {
    width: 100%;
  }
  .Form.payment .expiry-date-wrapper .form-group:nth-child(1) {
    margin-bottom: 20px;
  }
}

@media (max-width: 320px) {
  .inline-check-wrapper.billing-address .col {
    padding: 0px;
  }

  .inline-check-wrapper.billing-address .form-label {
    margin-right: 3%;
  }

  .Form.payment #cardsImg {
    margin-left: 0px;
    max-width: 145px;
    margin-top: 0px;
    padding-bottom: 5px;
  }

  .Form.payment .custom-label .price {
    top: 0px;
  }

  .Form.payment .custom-label p {
    width: 80%;
  }
  .Form.payment .expiry-date-wrapper {
    width: auto;
    min-width: auto;
  }
  .Form.payment .expiry-date-wrapper .form-group {
    width: 100%;
  }
  .Form.payment .expiry-date-wrapper .form-group:nth-child(1) {
    margin-bottom: 20px;
  }
}
