/* Pricing page styles */
.box-offer-wrapper {
  margin-top: 35px;
  margin-bottom: 35px;
  text-align: center;
}
.box-offer {
  width: auto;
  max-width: 254px;
  display: inline-block;
  vertical-align: top;
  margin-left: 30px;
}
.box-offer.selected {
  background-color: #101661;
  -webkit-box-shadow: 3px 9px 44px 5px rgba(0, 50, 113, 0.1);
  box-shadow: 3px 9px 44px 5px rgba(0, 50, 113, 0.1);
}
.box-offer:first-child {
  margin-left: 0;
}
.box-offer .header {
  background-color: #101661;
  text-align: center;
  height: 40px;
  padding-top: 7px;
}
.box-offer.selected .header {
  background-color: #1a74e6;
}
.box-offer .header h6 {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.box-offer .copy {
  border: 1px solid #ccc;
  border-top: 0px;
}
.box-offer .copy .price-wrapper {
  border-bottom: 1px solid #ccc;
  text-align: center;
  padding: 22px 0 30px 0;
  width: 80%;
  margin: 0 auto;
}
.box-offer .copy .price-wrapper p {
  color: #434343;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.box-offer .copy .price-wrapper .price span {
  color: #101661;
  font-size: 30px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
  margin-top: 8px;
  margin-right: 3px;
}
.box-offer.selected .copy .price-wrapper .price span,
.box-offer.selected .copy .price-wrapper .price p {
  color: #fff;
}
.box-offer .copy .price-wrapper .price p {
  color: #1a74e6;
  font-size: 72px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  display: inline;
  padding: 0px;
  margin: 0px;
  line-height: 72px;
  position: relative;
  top: 5px;
}
.box-offer.selected .price {
  margin-top: 22px;
  margin-bottom: 22px;
}
.box-offer .copy ul {
  margin: 30px 20px 50px 20px;
  text-align: left;
}
.box-offer .copy ul li {
  color: #434343;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
  list-style: none;
  margin-bottom: 20px;
}
.box-offer.selected .copy ul li {
  color: #fff;
}

.box-offer .copy ul li {
  list-style-image: url(../img/pricing-blue-check.png);
}
.box-offer.selected .copy ul li {
  list-style-image: url(../img/pricing-white-check.png);
}

.home.about.pricing p {
  width: auto;
}

/* Mobile view */
@media (max-width: 1280px) {
  .box-offer {
    margin-left: 0px;
    margin-bottom: 20px;
    max-width: 100%;
    display: block;
  }
  .box-offer-wrapper {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
/*iPad Pro*/
@media (max-width: 1024px) and (min-width: 769px) {
}
/*iPad*/
@media (max-width: 768px) and (min-width: 415px) {
}
@media (max-width: 414px) {
}
