.navbar-nav li a:link,
.navbar-nav li a:visited {
  display: inline;
  color: #fff;
  text-decoration: none;
}

.navbar-nav > li {
  display: inline;
  color: #fff;
  margin-right: 40px;
  text-transform: uppercase;
}
.navbar-nav > li:last-child {
  margin-right: 0px;
}
.navbar-dark .navbar-brand,
.navbar-expand-md {
  padding: 0px !important;
}

.navbar-expand-md .navbar-nav {
  float: right;
}
.navbar-dark .navbar-brand {
  margin-right: 27%;
}

.mobile {
  display: none !important;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 1;
  max-width: 1000px;
}
@media (max-width: 1280px) {
  .navbar-dark .navbar-brand {
    margin-right: calc(28% - 3%);
  }
}

/*iPad Pro*/
@media (max-width: 1024px) and (min-width: 769px) {
  .navbar-dark .navbar-brand {
    margin-right: 20%;
  }
}
/*iPad*/
@media (max-width: 768px) and (min-width: 415px) {
  .navbar-dark .navbar-brand {
    margin-right: 6%;
  }
  .navbar-nav > li {
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .sticky {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .mobile {
    display: initial !important;
  }
  .navbar-nav {
    border-bottom: 1px solid #000;
    box-shadow: inset 0 -1px 0 #2b2d2d;
  }
  .navbar-toggler {
    margin-left: 0%;
  }
  .navbar {
    margin-top: 0px;
    padding: 0px !important;
  }
  .navbar-collapse {
    background-color: #101661;
    z-index: 10;
  }
  .navbar-brand {
    margin-right: 0px !important;
  }
  .navbar {
    height: auto;
  }
  .navbar-nav > li {
    text-align: left;
    border-right: 0px;
    display: block;
    padding-left: 10px;
    width: 90%;
  }
  .navbar-nav > li a.selected,
  .navbar-nav > li a.selected:hover {
    border-top: 0px;
    border-right: 5px solid #7abb00;
  }
  .navbar-nav > li a:hover {
    border-top: 0px;
  }
  .navbar-nav > li a {
    display: block !important;
    border-top: 1px solid #fff;
    box-shadow: inset 0 -1px 0 #2b2d2d;
  }
  .navbar-toggler {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .navbar-collapse {
    margin-top: 5px;
  }
  .navbar.sticky {
    width: 100%;
  }
  .navbar-expand-md .navbar-nav {
    float: none;
    padding-left: 10px;
  }
  .navbar-dark .navbar-brand {
    width: 78%;
  }
}

@media screen and (min-width: 0\0) {
  /* IE9 and IE10 rule sets go here */
  .navbar .collapse:not(.show) {
    display: block;
  }
}
